import React, { Fragment, useRef, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { usePopper } from 'react-popper'
import { classNames } from '../../../utils/misc'
import { Popover, Transition } from '@headlessui/react'

const triangle = {
  width: 20,
  height: 10,
}

const triangleStyle = {
  top: {
    className: 'border-t-blue-600',
    style: {
      bottom: -triangle.height,
      borderTopWidth: triangle.height,
      borderRightWidth: triangle.width / 2,
      borderBottomWidth: 0,
      borderLeftWidth: triangle.width / 2,
    },
  },
  right: {
    className: 'border-r-blue-600',
    style: {
      left: -triangle.height,
      borderTopWidth: triangle.width / 2,
      borderRightWidth: triangle.height,
      borderBottomWidth: triangle.width / 2,
      borderLeftWidth: 0,
    },
  },
  bottom: {
    className: 'border-b-blue-600',
    style: {
      top: -triangle.height,
      borderTopWidth: 0,
      borderRightWidth: triangle.width / 2,
      borderBottomWidth: triangle.height,
      borderLeftWidth: triangle.width / 2,
    },
  },
  left: {
    className: 'border-l-blue-600',
    style: {
      right: -triangle.height,
      borderTopWidth: triangle.width / 2,
      borderRightWidth: 0,
      borderBottomWidth: triangle.width / 2,
      borderLeftWidth: triangle.height,
    },
  },
}

const Tooltip = ({
  title,
  description,
  placement = 'top',
  defaultClass = 'text-gray-400 hover:text-gray-500',
  activeClass = 'text-blue-600',
}) => {
  const popperElRef = useRef(null)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, triangle.height],
        },
      },
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
  })
  let currentPlacement = attributes?.popper?.['data-popper-placement']
  currentPlacement = currentPlacement ? currentPlacement : placement

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="flex items-center"
          >
            <QuestionMarkCircleIcon
              className={classNames(
                'w-6 h-6',
                open ? activeClass : defaultClass
              )}
            />
          </Popover.Button>

          <div
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}
            className="z-20"
          >
            <Transition
              as={Fragment}
              show={open}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              <Popover.Panel
                className={classNames(
                  'w-[288px] z-10 bg-blue-600 rounded px-4 py-2 text-white shadow-md'
                )}
              >
                <div className="flex items-center space-x-2 text-gray-200">
                  <QuestionMarkCircleIcon className="w-6 h-6" />
                  <p className="text-xs leading-4 font-semibold tracking-wider uppercase whitespace-nowrap">
                    {title}
                  </p>
                </div>
                <p className="mt-1 text-sm leading-5 text-white">
                  {description}
                </p>
                <div
                  ref={setArrowElement}
                  className={classNames(
                    'border-transparent',
                    triangleStyle[currentPlacement].className
                  )}
                  style={{
                    ...styles.arrow,
                    ...triangleStyle[currentPlacement].style,
                  }}
                />
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  )
}

export default Tooltip
